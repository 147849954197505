import React, { useReducer, createContext, useEffect } from "react";
import { db, storage } from "../firebase/Firebase";
import { query, addDoc, getDocs, collection, where, getDoc, doc, setDoc, updateDoc, writeBatch, arrayUnion, arrayRemove, serverTimestamp, deleteDoc } from "firebase/firestore";
import { UserContext } from "./UserContext";

const LinkMessageContext = createContext();

const linkMessagesReducer = (state, action) => {
    switch (action.type) {
        case "SET_LINKMESSAGES":
            return action.payload;
        case "ADD_LINKMESSAGE":
            return [...state, action.payload];
        case "UPDATE_LINKMESSAGE":
            return state.map(linkMessage => {
                if (linkMessage.id === action.payload.id) {
                    return action.payload;
                } else {
                    return linkMessage;
                }
            });
        case "DELETE_LINKMESSAGE":
            return state.filter(linkMessage => linkMessage.id !== action.payload.id);
        case "UPDATE_SOME_LINKMESSAGES":
            return state.map(linkMessage => {
                const updatedLinkMessage = action.payload.find(p => p.id === linkMessage.id);
                if (updatedLinkMessage) {
                    return updatedLinkMessage;
                } else {
                    return linkMessage;
                }
            });
        default:
            return state;
    }
};


const LinkMessageProvider = ({ children }) => {
    const [linkMessages, linkMessagesDispatch] = useReducer(linkMessagesReducer, []);

    const { currentUser } = React.useContext(UserContext);

    // useEffect(() => {
    //     const unsubscribe = async () => {
    //         await fetchLinkMessages();
    //         console.log("linkMessages fetched");
    //     }
    //     unsubscribe();
    // }, []);

    // never used it, but it might be useful in the future
    const emptyAllLinkMessageContext = () => {
        linkMessagesDispatch({ type: "SET_LINKMESSAGES", payload: [] });
    }

    const fetchLinkMessages = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "linkMessages"));
            const linkMessages = querySnapshot.docs.map(doc => doc.data());
            linkMessagesDispatch({ type: "SET_LINKMESSAGES", payload: linkMessages });
            console.log("linkMessages fetched for all users");
        } catch (error) {
            console.log(error);
        }
    };
    const fetchLinkMessagesForCurrentUserCommunity = async () => {
        try {
            const q = query(collection(db, "linkMessages"), where("communityId", "==", currentUser.community.id));
            const querySnapshot = await getDocs(q);
            const linkMessages = querySnapshot.docs.map(doc => doc.data());
            linkMessagesDispatch({ type: "SET_LINKMESSAGES", payload: linkMessages });
            console.log("linkMessages fetched for current user community");
            return linkMessages;
        } catch (error) {
            console.log(error);
        }
    };

    const fetchLinkMessage = async (linkMessageId) => {
        try {
            const docRef = doc(db, "linkMessages", linkMessageId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                console.log("linkMessage data:", docSnap.data());
                return docSnap.data();
            } else {
                console.log("linkMessage not found");
            }
        } catch (error) {
            console.log(error);
        }
    };




    // fetchlinkMessages for the current user by matching currentUser.phone with linkMessage.pickedUser.phone
    const fetchLinkMessagesByCurrentUser = async () => {
        try {
            const q = query(collection(db, "linkMessages"), where("createdBy", "==", currentUser.id));
            const querySnapshot = await getDocs(q);
            const linkMessages = querySnapshot.docs.map(doc => doc.data());
            linkMessagesDispatch({ type: "UPDATE_SOME_LINKMESSAGES", payload: linkMessages });
            console.log("linkMessages fetched for current user");
            return linkMessages;
        } catch (error) {
            console.log(error);
        }
    };

    const fetchLinkMessagesByFriend = async (friend) => {
        try {
            const q = query(collection(db, "linkMessages"), where("createdBy", "==", friend.id));
            const querySnapshot = await getDocs(q);
            const linkMessages = querySnapshot.docs.map(doc => doc.data());
            linkMessagesDispatch({ type: "UPDATE_SOME_LINKMESSAGES", payload: linkMessages });
            console.log("linkMessages fetched for friend");
            return linkMessages;
        } catch (error) {
            console.log(error);
        }
    };

    const fetchLinkMessagesByFriends = async (friendsIdsArray) => {
        try {
            const q = query(collection(db, "linkMessages"), where("createdBy", "in", friendsIdsArray));
            const querySnapshot = await getDocs(q);
            const linkMessages = querySnapshot.docs.map(doc => doc.data());
            linkMessagesDispatch({ type: "UPDATE_SOME_LINKMESSAGES", payload: linkMessages });
            console.log("linkMessages fetched for friends");
            return linkMessages;
        } catch (error) {
            console.log(error);
        }
    };



    const createLinkMessage = async (linkMessage) => {
        try {
            let newLinkMessage = {
                ...linkMessage,
                createdAt: serverTimestamp(),
            };
            const docRef = await addDoc(collection(db, "linkMessages"), newLinkMessage);
            await setDoc(doc(db, "linkMessages", docRef.id), { id: docRef.id }, { merge: true });

            // Read the linkMessage back from Firestore
            const docSnap = await getDoc(doc(db, "linkMessages", docRef.id));
            newLinkMessage = { id: docRef.id, ...docSnap.data() };

            linkMessagesDispatch({
                type: "ADD_LINKMESSAGE",
                payload: newLinkMessage
            });
            console.log("linkMessage created");
            return {
                linkMessage: newLinkMessage
            };
        } catch (error) {
            console.log(error);
            throw error;
            return {
                error: error.message
            };
        }

    };

    // this is for testing purposes only
    const createLinkMessageWithoutFirestoreUpdate = async (linkMessage) => {
        linkMessagesDispatch({
            type: "ADD_LINKMESSAGE",
            payload: linkMessage
        });
        console.log("linkMessage created without firestore update");
    };




    const updateLinkMessage = async (linkMessage) => {
        let updatedLinkMessage = {
            ...linkMessage,
            updatedAt: serverTimestamp(),
            updatedBy: currentUser.id,
        };
        try {
            await setDoc(doc(db, "linkMessages", updatedLinkMessage.id), updatedLinkMessage, { merge: true });
            linkMessagesDispatch({
                type: "UPDATE_LINKMESSAGE",
                payload: updatedLinkMessage
            });
            console.log("linkMessage updated");
            return {
                linkMessage: updatedLinkMessage
            };
        } catch (error) {
            console.log(error);
            throw error;
            return {
                error: error.message
            };
        }
    }



    const deleteLinkMessage = async (linkMessage) => {
        try {
            await deleteDoc(doc(db, "linkMessages", linkMessage.id));
            linkMessagesDispatch({
                type: "DELETE_LINKMESSAGE",
                payload: linkMessage
            });
            return {
                linkMessage: linkMessage
            };
        } catch (error) {
            console.log(error);
            throw error;
            return {
                error: error.message
            };
        }
    };


    const bulkUploadLinkMessages = async (linkMessages) => {
        try {
            const batch = writeBatch(db);
            linkMessages.forEach(linkMessage => {
                const linkMessageRef = doc(collection(db, "linkMessages"))
                batch.set(linkMessageRef, {
                    ...linkMessage,
                    createdAt: serverTimestamp(),
                    createdBy: currentUser.id,
                    id: linkMessageRef.id
                });
            });
            await batch.commit();
            console.log("linkMessages uploaded");
        } catch (error) {
            console.log(error);
            return {
                error: error.message
            };
        }
    }

    const bulkDeleteLinkMessages = async (linkMessages) => {
        try {
            const batch = writeBatch(db);
            linkMessages.forEach(linkMessage => {
                const linkMessageRef = doc(db, "linkMessages", linkMessage.id);
                batch.delete(linkMessageRef);
            });
            await batch.commit();
            console.log("linkMessages deleted");
        } catch (error) {
            console.log(error);
            return {
                error: error.message
            };
        }
    }

    return (
        <LinkMessageContext.Provider value={{ linkMessages, emptyAllLinkMessageContext, fetchLinkMessages, fetchLinkMessagesForCurrentUserCommunity, fetchLinkMessage, fetchLinkMessagesByCurrentUser, fetchLinkMessagesByFriend, fetchLinkMessagesByFriends, createLinkMessage, createLinkMessageWithoutFirestoreUpdate, updateLinkMessage, deleteLinkMessage, bulkUploadLinkMessages, bulkDeleteLinkMessages }}>
            {children}
        </LinkMessageContext.Provider>
    );
};

export { LinkMessageContext, LinkMessageProvider };