import 'react-native-gesture-handler';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import UserLinkScreen from './src/screens/UserLinkScreen';
import DynamicScreen from './src/screens/DynamicScreen';
import SentMessageScreen from './src/screens/SentMessageScreen';


const MainStack = createStackNavigator();
const Stack = createStackNavigator();

const MainNavigator = () => {
    return (
        <MainStack.Navigator
            screenOptions={{
                // headerBackVisible: false,
                headerTitleAlign: "center",
                headerBackTitleVisible: false,
                headerShadowVisible: false,
                headerTintColor: "black",
                contentStyle: {
                    backgroundColor: "white",
                },
                navigationBarColor: "black",
                gestureEnabled: true,
                gestureDirection: 'horizontal',
                cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS
            }}
        >
            <MainStack.Screen
                name="Dynamic"
                component={DynamicScreen}
                options={{
                    headerShown: false,
                    title: 'Moots - send anonymous messages'
                }}
            />
            <MainStack.Screen
                name="SentMessageScreen"
                component={SentMessageScreen}
                options={{
                    headerTransparent: true,
                    headerTitle: "",
                    title: 'Moots - message sent',
                    headerTintColor: "white",
                }}
            />

        </MainStack.Navigator>
    );
};

export default MainNavigator;