import React, { useContext, useEffect, useState } from 'react'
import { StyleSheet, Text, View, Linking, ActivityIndicator, Platform } from 'react-native'
import { colors, opacommunities, spacings, textStyles, textInputStyles, ButtonOne, ButtonOneUnfilled, ButtonTwo, SelectStory, DividerVertical, DividerHorizontal, ButtonTwoUnfilled } from '../context/DesignSystem';
import { UserContext } from '../context/UserContext';
import UserLinkScreen from './UserLinkScreen';


const DynamicScreen = ({ route, navigation }) => {
    const { users, fetchUsers, fetchRules } = useContext(UserContext);
    const { pollLink } = route.params ?? {};
    const [isScreenLoading, setIsScreenLoading] = useState(true);


    console.log("pollLink", pollLink)

    useEffect(() => {
        const fetchUserOnLoad = async () => {
            await fetchUsers();
            await fetchRules();
            setIsScreenLoading(false);
        }
        fetchUserOnLoad();
    }, []);

    const user = users.find(user => user.pollLink && user.pollLink === pollLink);

    if (!pollLink && Platform.OS === 'web') {
        window.location.href = 'https://www.moots.fun';
        return null;
    }

    if (user) {
        return <UserLinkScreen user={user} navigation={navigation} />
    } else if (isScreenLoading) {
        return (
            <View style={styles.loadingContainer}>
                <ActivityIndicator size="large" color={colors.primary} />
            </View>
        )
    } else {

        if (Platform.OS === 'ios') {
            return <UserLinkScreen user={user} />
        } else {
            return (
                <View style={styles.userNotFoundContainer}>
                    <Text style={styles.icon} >🤷‍♀️</Text>
                    <Text style={styles.title} >User not found...</Text>
                </View>
            )
        }

    }
}

export default DynamicScreen

const styles = StyleSheet.create({
    loadingContainer: {
        flex: 1,
        backgroundColor: "white",
        padding: spacings.large * 2,
        justifyContent: "center",
        alignItems: "center",
    },
    userNotFoundContainer: {
        flex: 1,
        backgroundColor: "white",
        padding: spacings.large * 2,
        alignItems: "center",
    },
    icon: {
        textAlign: "center",
        fontSize: 60,
        marginTop: spacings.large * 2,
    },
    title: {
        ...textStyles.heading3,
        textAlign: "center",
        color: "black",
        fontWeight: "bold",
        textAlign: "center",
        marginTop: spacings.small,
    },

})