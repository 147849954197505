import React, { useContext, useState } from "react";
import { ActivityIndicator, StyleSheet, Text, View, TextInput, Button, TouchableOpacity, Image, ScrollView, Alert, Platform, useWindowDimensions } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { UserContext } from "./UserContext";


const colors = {
    primaryDark: '#0077c2',
    primary: '#14b6eb',
    primarySemiLight: '#8ee0ff',
    primaryLight: '#c0e8ff',

    secondaryDark: '#D68B00',
    secondary: '#F5A623',
    secondarySemiLight: '#FFC07F',
    secondaryLight: '#FFDFA0',

    textDark: '#434343',
    textDarker: '#2b2b2b',
    text: '#525252',
    textLight: '#7B7B7B',
    textOnSurfaceLight: '#e0e4fa',
    textOnSurfaceSemiDark: '#b0b4ca',
    textOnSurfaceDark: '#8a8fa3',
    textOnSurfaceDarker: '#6d687e',
    textOnSurfaceDarkest: '#3f3a4f',
    textDisabled: '#C4C4C4',
    textGreenDark: '#095043',

    borderDark: '#434343',
    border: '#7B7B7B',
    borderLight: '#C4C4C4',
    borderDisabled: '#E9E9E9',

    iconDark: '#434343',
    icon: '#7B7B7B',
    iconSemiLight: '#AAAAAA',
    iconLight: '#C4C4C4',
    iconDisabled: '#E9E9E9',
    iconChatRead: '#34b7f1',


    background: '#f9f8fd',
    backgroundLight: '#fbfbfb',
    backgroundSemiDark: '#f2f2f2',
    backgroundDark: '#ebebeb',
    backgroundDarkest: '#1A1A1A',
    backgroundTextInput: '#edecef',
    backgroundPrimary: '#edf7fc',
    backgroundSecondary: '#f1edfa',
    backgroundChat: '#ECE5DD',
    backgroundError: '#FFC7C7',
    backgroundErrorLight: '#FFF0F0',
    backgroundUnderReview: '#FFFBF1',
    backgroundActive: '#E9F8E9',

    surface: '#ffffff',
    surfaceDark: '#ecedf5',
    surfacePrimary: '#c0e8ff',
    surfaceSecondary: '#c9beff',
    surfaceSecondaryLight: '#f6f7f9',
    surfaceChatBubble: '#DCF8C6',
    surfaceYellow: '#FFEABA',


    borderMale: '#14b6eb',
    backgroundMale: '#c0e8ff',
    borderFemale: '#db044c',
    backgroundFemale: '#ffadec',


    confession: '#FFA500',
    backgroundConfession: '#FFF0E6',
    question: '#0180ee',
    backgroundQuestion: '#E6F2FF',
    shoutout: '#5C4033',
    backgroundShoutout: '#F2EDE6',
    crush: '#FF69B4',
    backgroundCrush: '#FFE6F0',
    event: '#8B5CF6',
    backgroundEvent: '#F0E6FF',
    poll: '#5C8BF6',
    backgroundPoll: '#E6F0FF',
    dmMe: '#91710c',
    backgroundDmMe: '#FFF8E6',
    meme: '#2DDFFF',
    backgroundMeme: '#E6F9FF',
    rip: '#434343',
    backgroundRip: '#E6E6E6',
    forSale: '#0B7B0B',
    backgroundForSale: '#E6FFE6',
    other: '#434343',
    backgroundOther: '#E6E6E6',

    error: '#800025',
    errorLight: '#B3003B',
    underReview: '#FCB03c',
    active: '#128c7e',
}

const textStyles = {
    heading: {
        fontSize: 20,
        fontWeight: 'bold',
        color: colors.textDark,
    },
    heading1: {
        fontSize: 18,
        fontWeight: 'bold',
        color: colors.textDark,
    },
    heading2: {
        fontSize: 17,
        fontWeight: 'bold',
        letterSpacing: -0.25,
        color: colors.text,
    },
    heading3: {
        fontSize: 15.5,
        fontWeight: 'bold',
        letterSpacing: -0.4,
        color: colors.text,
    },
    heading4: {
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: -0.4,
        color: colors.text,
    },
    body: {
        fontSize: 16,
        fontWeight: 'normal',
        letterSpacing: -0.25,
        color: colors.textDark,
    },
    caption: {
        fontSize: 12,
        fontWeight: 'normal',
        letterSpacing: -0.4,
        color: colors.textLight,
    },
    captionCompressed: {
        fontSize: 12,
        fontWeight: 'normal',
        letterSpacing: -0.5,
        color: colors.textLight,
    },
}
const iconSizes = {
    small: textStyles.caption.fontSize * 1.5,
    medium: textStyles.heading2.fontSize * 1.4,
    large: textStyles.heading1.fontSize * 1.6,
}
const opacities = {
    o1: 0.87,
    o2: 0.67,
    o3: 0.38,
    o4: 0.12,
}
const spacings = {
    small: 6,
    medium: 9,
    large: 12,
}
const textInputStyles = {
    large: {
        height: spacings.medium * 6,
        borderRadius: spacings.medium * 1.5,
        paddingHorizontal: spacings.medium * 2,
        ...textStyles.body,
        color: "black",
        letterSpacing: 0.5,
        backgroundColor: colors.backgroundTextInput,
    },
    largeTransparent: {
        height: spacings.medium * 6,
        paddingHorizontal: spacings.medium * 2,
        ...textStyles.heading,
        color: "black",
        letterSpacing: 0.5,
        textAlign: "center",
    }
}

const DividerHorizontal = ({ style }) => {
    return (
        <View style={{
            height: 1,
            backgroundColor: colors.borderDisabled,
            ...style,
        }} />
    )
}
const DividerVertical = ({ style }) => {
    return (
        <View style={{
            width: 1,
            backgroundColor: colors.borderDisabled,
            ...style,
        }} />
    )
}

const PostCategoryTag = ({ title, isSelected, onPress, disabled, style, textStyle, screenName }) => {
    const { rules } = useContext(UserContext)



    const isSmall = (screenName === "PostDetailsScreen" || screenName === "CommunityScreen") ? true : false

    const textColor = title === "CONFESSION" ? colors.confession
        : title === "QUESTION" ? colors.question
            : title === "SHOUTOUT" ? colors.shoutout
                : title === "CRUSH" ? colors.crush
                    : title === "EVENT" ? colors.event
                        : title === "POLL" ? colors.poll
                            : title === "DM ME" ? colors.dmMe
                                : title === "MEME" ? colors.meme
                                    : title === "RIP" ? colors.rip
                                        : title === "FOR SALE" ? colors.forSale
                                            : title === "OTHER" ? colors.other
                                                : colors.event

    const backGroundColor = title === "CONFESSION" ? colors.backgroundConfession
        : title === "QUESTION" ? colors.backgroundQuestion
            : title === "SHOUTOUT" ? colors.backgroundShoutout
                : title === "CRUSH" ? colors.backgroundCrush
                    : title === "EVENT" ? colors.backgroundEvent
                        : title === "POLL" ? colors.backgroundPoll
                            : title === "DM ME" ? colors.backgroundDmMe
                                : title === "MEME" ? colors.backgroundMeme
                                    : title === "RIP" ? colors.backgroundRip
                                        : title === "FOR SALE" ? colors.backgroundForSale
                                            : title === "OTHER" ? colors.backgroundOther
                                                : colors.backgroundEvent

    return (
        <TouchableOpacity
            onPress={onPress}
            disabled={disabled}
            style={{
                backgroundColor: isSelected ? backGroundColor : colors.surface,
                borderWidth: isSelected ? 0 : 1,
                borderColor: isSelected ? backGroundColor : colors.borderDisabled,
                borderRadius: spacings.large * 2,
                paddingHorizontal: isSmall ? spacings.large : spacings.large * 1.5,
                paddingVertical: isSmall ? spacings.small : spacings.small * 1.5,
                ...style,
            }} >
            <Text style={{
                ...textStyles.caption,
                fontSize: isSmall ? textStyles.caption.fontSize * 0.8 : textStyles.caption.fontSize,
                fontWeight: isSelected ? "600" : "500",
                color: textColor,
                ...textStyle,
            }} >{title}</Text>
        </TouchableOpacity>
    )
}

const ButtonOne = ({ title, onPress, style, disabled, textStyle, isLoading }) => {
    return (
        <TouchableOpacity
            onPress={onPress}
            disabled={disabled || isLoading}
            style={{
                backgroundColor: disabled ? colors.primaryLight : colors.primary,
                height: spacings.large * 6,
                borderRadius: spacings.large * 3,
                justifyContent: 'center',
                paddingHorizontal: spacings.medium * 2,
                ...style,
            }}>
            {isLoading
                ? <ActivityIndicator size="small" color="white" />
                : <Text style={{
                    ...textStyles.heading,
                    color: "white",
                    textAlign: "center",
                    ...textStyle,
                }}>
                    {title}
                </Text>
            }
        </TouchableOpacity>
    )
}
const ButtonOneUnfilled = ({ title, onPress, style, disabled, textStyle, isLoading }) => {
    return (
        <TouchableOpacity
            onPress={onPress}
            disabled={disabled || isLoading}
            style={{
                backgroundColor: colors.surface,
                height: spacings.large * 5,
                borderRadius: spacings.large * 3,
                borderColor: disabled ? colors.primaryLight : colors.primary,
                borderWidth: 1,
                paddingHorizontal: spacings.medium * 2,
                justifyContent: 'center',
                ...style,
            }}>
            {isLoading
                ? <ActivityIndicator size="small" color={colors.primary} />
                : <Text style={{
                    ...textStyles.heading,
                    color: disabled ? colors.textDisabled : "black",
                    textAlign: "center",
                    ...textStyle,
                    // change font family to "Roboto" for web
                    // fontFamily: Platform.OS === 'web' ? "Roboto" : undefined,
                }}>
                    {title}
                </Text>
            }
        </TouchableOpacity>
    )
}
const ButtonTwo = ({ title, onPress, style, disabled, textStyle, isLoading }) => {
    return (
        <TouchableOpacity
            onPress={onPress}
            disabled={disabled || isLoading}
            style={{
                backgroundColor: disabled ? colors.primaryLight : colors.primary,
                height: spacings.large * 4,
                borderRadius: spacings.large * 2,
                justifyContent: 'center',
                alignSelf: "flex-start",
                paddingHorizontal: spacings.large * 1.5,
                ...style,
            }}>
            {isLoading
                ? <ActivityIndicator size="small" color="white" />
                : <Text style={{
                    ...textStyles.heading3,
                    fontWeight: "600",
                    color: "white",
                    textAlign: "center",
                    ...textStyle,
                }}>
                    {title}
                </Text>
            }
        </TouchableOpacity>
    )
}
const ButtonTwoUnfilled = ({ title, onPress, style, disabled, textStyle, isLoading }) => {
    return (
        <TouchableOpacity
            onPress={onPress}
            disabled={disabled || isLoading}
            style={{
                backgroundColor: colors.surface,
                height: spacings.large * 4,
                borderRadius: spacings.large * 2,
                borderColor: disabled ? colors.primaryLight : colors.primary,
                borderWidth: 1,
                justifyContent: 'center',
                alignSelf: "flex-start",
                paddingHorizontal: spacings.large * 1.5,
                ...style,
            }}>
            {isLoading
                ? <ActivityIndicator size="small" color={colors.primary} />
                : <Text style={{
                    ...textStyles.heading3,
                    fontWeight: "600",
                    color: disabled ? colors.textDisabled : "black",
                    textAlign: "center",
                    ...textStyle,
                }}>
                    {title}
                </Text>
            }
        </TouchableOpacity>
    )
}

const ButtonTwoUnfilledForAccept = ({ onPress, style, disabled, isLoading }) => {
    return (
        <TouchableOpacity
            onPress={onPress}
            disabled={disabled || isLoading}
            style={{
                backgroundColor: colors.surface,
                height: spacings.large * 4,
                width: spacings.large * 4,
                borderRadius: spacings.large * 2,
                borderColor: disabled ? colors.primaryLight : colors.primary,
                borderWidth: 1,
                justifyContent: 'center',
                alignItems: 'center',
                ...style,
            }}>
            {isLoading
                ? <ActivityIndicator size="small" color={colors.primary} />
                : <MaterialIcons name="check" size={iconSizes.large} color={disabled ? colors.primaryLight : colors.primary} />
            }
        </TouchableOpacity>
    )
}

const ButtonTwoUnfilledForIgnore = ({ onPress, style, disabled, isLoading }) => {
    return (
        <TouchableOpacity
            onPress={onPress}
            disabled={disabled || isLoading}
            style={{
                backgroundColor: colors.surface,
                height: spacings.large * 4,
                width: spacings.large * 4,
                borderRadius: spacings.large * 2,
                borderColor: disabled ? colors.borderLight : colors.border,
                borderWidth: 1,
                justifyContent: 'center',
                alignItems: 'center',
                ...style,
            }}>
            {isLoading
                ? <ActivityIndicator size="small" color={colors.border} />
                : <MaterialIcons name="close" size={iconSizes.large} color={disabled ? colors.borderLight : colors.border} />
            }
        </TouchableOpacity>
    )
}

const CheckedBoxes = ({ titles, checkedTitles, containerStyle, buttonStyle, textStyle }) => {
    const checkedBoxes = titles.map((title, index) => {
        checkedTitles = checkedTitles || []
        return (
            <View
                key={index}
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: checkedTitles.includes(title) ? colors.backgroundPrimary : colors.background,
                    borderRadius: spacings.small,
                    paddingHorizontal: spacings.large,
                    marginBottom: spacings.small,
                    paddingVertical: spacings.large,
                    ...buttonStyle,
                }} >
                {checkedTitles.includes(title)
                    ? <MaterialIcons name="check" size={iconSizes.medium} color={colors.primary} />
                    : <MaterialIcons name="check" size={iconSizes.medium} color={colors.icon} />
                }
                <Text style={{
                    flex: 1,
                    ...textStyles.heading3,
                    fontWeight: "normal",
                    color: checkedTitles.includes(title) ? "black" : colors.textDark,
                    marginLeft: spacings.medium,
                    ...textStyle,
                }} >{title}</Text>
            </View>
        )
    })
    return (
        <View style={{ ...containerStyle }}>
            {checkedBoxes}
        </View>
    )
}
const CheckBoxes = ({ titles, selected, setSelected, containerStyle, buttonStyle, textStyle }) => {
    const checkBoxes = titles.map((title, index) => {
        selected = selected || []
        return (
            <TouchableOpacity
                key={index}
                onPress={() => {
                    if (selected.includes(title)) {
                        setSelected(selected.filter(item => item !== title))
                    } else {
                        setSelected([...selected, title])
                    }
                }}
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: selected.includes(title) ? colors.backgroundPrimary : colors.background,
                    borderRadius: spacings.small,
                    paddingHorizontal: spacings.large,
                    marginBottom: spacings.small,
                    paddingVertical: spacings.large * 1.3,
                    ...buttonStyle,
                }} >
                {selected.includes(title)
                    ? <MaterialIcons name="check-box" size={iconSizes.small} color={colors.primary} />
                    : <MaterialIcons name="check-box-outline-blank" size={iconSizes.small} color={colors.icon} />
                }
                <Text
                    style={{
                        flex: 1,
                        ...textStyles.heading3,
                        fontWeight: "normal",
                        color: selected.includes(title) ? "black" : colors.textDark,
                        marginLeft: spacings.medium,
                        ...textStyle,
                    }}
                >
                    {title}
                </Text>
            </TouchableOpacity>
        )
    })
    return (
        <View style={{ ...containerStyle }}>
            {checkBoxes}
        </View>
    )
}
const CheckBoxesTemp = ({ titles, selected, setSelected, containerStyle, buttonStyle, textStyle }) => {
    const checkBoxes = titles.map((title, index) => {
        selected = selected || []
        return (
            <TouchableOpacity
                key={index}
                onPress={() => setSelected(title)}
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: selected.includes(title) ? colors.backgroundPrimary : colors.background,
                    borderRadius: spacings.small,
                    paddingHorizontal: spacings.large,
                    marginBottom: spacings.small,
                    paddingVertical: spacings.large * 1.3,
                    ...buttonStyle,
                }} >
                {selected.includes(title)
                    ? <MaterialIcons name="check-box" size={iconSizes.small} color={colors.primary} />
                    : <MaterialIcons name="check-box-outline-blank" size={iconSizes.small} color={colors.icon} />
                }
                <Text style={{
                    flex: 1,
                    ...textStyles.heading3,
                    fontWeight: "normal",
                    color: selected.includes(title) ? "black" : colors.textDark,
                    marginLeft: spacings.medium,
                    ...textStyle,
                }} >{title}</Text>
            </TouchableOpacity>
        )
    })
    return (
        <View style={{ ...containerStyle }}>
            {checkBoxes}
        </View>
    )
}

const RadioButtons = ({ titles, selected, setSelected, buttonStyle, containerStyle }) => {
    const radioButtons = titles.map((title, index) => {
        return (
            <TouchableOpacity
                key={index}
                onPress={() => setSelected(title)}
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: selected === title ? colors.backgroundPrimary : colors.background,
                    height: spacings.medium * 4,
                    borderRadius: 4,
                    paddingLeft: spacings.large,
                    paddingRight: spacings.large * 1.2,
                    marginBottom: spacings.small,
                    ...buttonStyle,
                }} >
                {selected === title
                    ? <MaterialIcons name="radio-button-checked" size={iconSizes.small} color={colors.primary} />
                    : <MaterialIcons name="radio-button-unchecked" size={iconSizes.small} color={colors.icon} />
                }
                <Text style={{
                    ...textStyles.heading3,
                    fontWeight: "normal",
                    color: selected === title ? "black" : colors.textDark,
                    marginLeft: spacings.medium,
                }} >{title}</Text>
            </TouchableOpacity>
        )
    })
    return (
        <View
            style={{ ...containerStyle }}
        >
            {radioButtons}
        </View>
    )
}
const RadioButtonsTemp = ({ titles, selected, setSelected, buttonStyle, containerStyle, horizontal }) => {
    const radioButtons = titles.map((title, index) => {
        return (
            <TouchableOpacity
                key={index}
                onPress={() => setSelected(title)}
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: selected === title ? colors.backgroundPrimary : colors.background,
                    height: spacings.medium * 4,
                    borderRadius: 4,
                    paddingHorizontal: spacings.large,
                    marginBottom: spacings.small,
                    ...buttonStyle,
                }} >
                {selected === title
                    ? <MaterialIcons name="radio-button-checked" size={iconSizes.small} color={colors.primary} />
                    : <MaterialIcons name="radio-button-unchecked" size={iconSizes.small} color={colors.icon} />
                }
                <Text style={{
                    ...textStyles.heading3,
                    fontWeight: "normal",
                    color: selected === title ? "black" : colors.textDark,
                    marginLeft: spacings.medium,
                }} >{title}</Text>
            </TouchableOpacity>
        )
    })
    return (
        <ScrollView
            horizontal={horizontal}
            style={{ ...containerStyle }}
        >
            {radioButtons}
        </ScrollView>
    )
}
const TextInputWithDropdown = ({ titles, value, onChangeText, onSelect, onFocus, onBlur, placeholder, multiline, editable, containerStyle, buttonStyle, dropdownContainerStyle, style }) => {

    // In iOS: for dropdown to stay on top, 
    // the View containing the TextInputWithDropdown must be set with zIndex higher than the components below

    value = value || ""
    const filteredTitles = titles.filter(title => {
        return title.toLowerCase().includes(value.toLowerCase())

    })
    const [dropdownVisible, setDropdownVisible] = useState(false)

    const dropDown = filteredTitles.map((title, index) => {
        return (
            <TouchableOpacity
                key={index}
                onPress={() => {
                    onChangeText(title)
                    if (onSelect) {
                        onSelect(title)
                    }
                    setDropdownVisible(false)
                }}
                style={{
                    flexDirection: "row",
                    alignItems: "center",
                    backgroundColor: value === title ? colors.backgroundPrimary : colors.backgroundLight,
                    height: spacings.medium * 4,
                    borderRadius: 4,
                    marginTop: spacings.small,
                    ...buttonStyle,
                }} >
                <Text style={{
                    ...textStyles.heading3,
                    fontWeight: "normal",
                    color: value === title ? "black" : colors.textDark,
                    marginLeft: spacings.medium,
                }} >
                    {title}
                </Text>
            </TouchableOpacity>
        )
    })
    return (
        <View style={{
            ...containerStyle
        }}>
            <TextInput
                style={{
                    ...textInputStyles.large,
                    ...style,
                }}
                value={value}
                editable={editable}
                onChangeText={(text) => {
                    onChangeText(text)
                    setDropdownVisible(true)
                }}
                placeholder={placeholder}
                multiline={multiline}
                onSubmitEditing={() => setDropdownVisible(false)}
                onFocus={() => {
                    setDropdownVisible(false)
                    if (onFocus) {
                        onFocus()
                    }
                }}
                onBlur={() => {
                    if (onBlur) {
                        onBlur()
                    }
                }}
            />
            {value.length > 0 && dropdownVisible &&
                <ScrollView style={{
                    position: "absolute",
                    top: textInputStyles.large.height,
                    left: 0,
                    right: 0,
                    elevation: 1,
                    backgroundColor: colors.surface,
                    borderRadius: 4,
                    borderColor: colors.borderDisabled,
                    borderWidth: 1,
                    paddingHorizontal: spacings.large,
                    paddingVertical: spacings.small,
                    maxHeight: textInputStyles.large.height * 5,
                    ...dropdownContainerStyle,
                }} >
                    {dropDown}
                </ScrollView>
            }

        </View>
    )
}

const alertPolyfill = (title, description, options, extra) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'))

    if (result) {
        const confirmOption = options.find(({ style }) => style !== 'cancel')
        confirmOption && confirmOption.onPress()
    } else {
        const cancelOption = options.find(({ style }) => style === 'cancel')
        cancelOption && cancelOption.onPress()
    }
}
const AlertAllPlatforms = Platform.OS === 'web' ? alertPolyfill : Alert.alert

const getWindowsHeight = () => {
    const { height: windowHeight } = useWindowDimensions();
    const PAGE_HEIGHT = windowHeight;
    return PAGE_HEIGHT;
}
const getWindowsWidth = () => {
    const { width: windowWidth } = useWindowDimensions();
    const PAGE_WIDTH = windowWidth;
    return PAGE_WIDTH;
}





export { colors, opacities, spacings, iconSizes, textStyles, textInputStyles, DividerHorizontal, DividerVertical, PostCategoryTag, ButtonOne, ButtonOneUnfilled, ButtonTwo, ButtonTwoUnfilled, ButtonTwoUnfilledForAccept, ButtonTwoUnfilledForIgnore, CheckedBoxes, CheckBoxes, CheckBoxesTemp, RadioButtons, RadioButtonsTemp, TextInputWithDropdown, AlertAllPlatforms, getWindowsHeight, getWindowsWidth };
