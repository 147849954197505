import React, { useContext, useEffect, useState, useCallback } from 'react';
import { ActivityIndicator, StatusBar, StyleSheet, ScrollView, Text, TextInput, View, SafeAreaView, Image, FlatList, useWindowDimensions, TouchableOpacity, KeyboardAvoidingView, TouchableWithoutFeedback, Keyboard, Modal, Share, Platform, Linking } from 'react-native';
import { colors, opacommunities, spacings, textStyles, textInputStyles, ButtonOne, ButtonOneUnfilled, ButtonTwo, SelectStory, DividerVertical, DividerHorizontal, ButtonTwoUnfilled } from '../context/DesignSystem';
import HeaderLeft from '../components/HeaderLeft';
import { MaterialIcons } from '@expo/vector-icons';
import { getRandomColor, formatTime } from '../hooks/Hooks';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { LinkMessageContext } from '../context/LinkMessageContext';
import { UserContext } from '../context/UserContext';

const SentMessageScreen = ({ navigation, route }) => {
    const insets = useSafeAreaInsets();
    const { pollLink } = route.params ?? {};
    const { linkMessages } = useContext(LinkMessageContext);
    const { rules } = useContext(UserContext);
    console.log("linkMessages", linkMessages)

    const linkMessage = linkMessages.find(lm => lm.pickedUser.pollLink && lm.pickedUser.pollLink === pollLink);
    console.log("linkMessage", linkMessage)

    const storyContainerBackgroundColor = linkMessage ? linkMessage.backgroundColor : getRandomColor();

    const { width, height } = useWindowDimensions();
    const storyContainerWidth = width > 500 ? 500 : width;

    const appStoresUrl = rules?.urlBothAppStores ?? "https://mootsapp.app.link/moots";

    React.useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => (
                <HeaderLeft
                    pollLink={linkMessage?.pickedUser?.pollLink}
                />
            ),
        });
    }, [navigation]);


    return (
        <View style={[styles.container, {
            paddingTop: insets.top,
            paddingBottom: insets.bottom,
        }]}>
            <View style={[styles.storyContainer, {
                backgroundColor: storyContainerBackgroundColor,
                width: storyContainerWidth,
            }]}>
                <View style={styles.bottomContainer}>
                    <View style={styles.checkIconContainer}>
                        <MaterialIcons name="check-circle" size={100} color={storyContainerBackgroundColor} />
                    </View>

                    <Text style={styles.instructionMessage}>
                        Sent!
                    </Text>
                    <ButtonOneUnfilled
                        title="🔗 Get your own link"
                        onPress={() => {
                            // open appStoresUrl
                            window.location.href = appStoresUrl;
                        }}
                        style={styles.getLinkButton}
                        textStyle={{ color: "black" }}
                    />
                    <Text
                        style={styles.goBackMessageLink}
                        onPress={() => {
                            navigation.navigate("Dynamic", { pollLink: linkMessage?.pickedUser?.pollLink })
                        }}
                    >
                        Send another message
                    </Text>
                </View>

            </View>
        </View>
    )
}

export default SentMessageScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.backgroundDarkest,
        alignItems: "center",
        // borderWidth: 1,
    },
    storyContainer: {
        flex: 1,
        justifyContent: "center",
        borderRadius: spacings.medium,
        padding: spacings.large * 2,
    },
    title: {
        ...textStyles.heading,
        fontSize: 28,
        color: "black",
        fontWeight: "bold",
        textAlign: "center",
        marginHorizontal: spacings.large,
    },
    subTitle: {
        ...textStyles.body,
        fontSize: 16,
        textAlign: "center",
        marginTop: spacings.small,
        marginHorizontal: spacings.large,
        marginBottom: spacings.large,
    },
    verifyEmailContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: spacings.large,
        paddingVertical: spacings.large * 2,
        borderRadius: spacings.large * 2,
        marginTop: spacings.large * 2,
        borderWidth: 1,
        borderColor: colors.borderDisabled,
    },
    sectionContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: colors.surfaceSecondaryLight,
        padding: spacings.large,
        paddingVertical: spacings.large * 2,
        borderRadius: spacings.large * 1.5,
        // marginTop: spacings.large * 2,
    },

    userContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: spacings.large * 2,
    },
    userImage: {
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: colors.textOnSurfaceLight,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: spacings.small,
        borderWidth: 2,
        borderColor: colors.secondaryDark,
    },
    userInitials: {
        fontSize: 20,
        fontWeight: "bold",
        color: colors.textOnSurfaceDarker,
        letterSpacing: 1,
    },
    userPollLink: {
        flex: 1,
        ...textStyles.heading,
        fontSize: 16,
        color: colors.textOnSurfaceLight,
        fontWeight: "600",
        letterSpacing: 0.5,
    },
    OpenInAppButton: {
        alignSelf: "center",
        marginLeft: "auto",
        marginRight: 0,
        height: 30,
        paddingHorizontal: spacings.large,
        borderColor: "white",
        backgroundColor: "transparent",
    },

    verifyEmailTextContainer: {
        flex: 1,
        marginHorizontal: spacings.large,
    },
    userMessage: {
        ...textStyles.heading2,
        color: "black",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: spacings.large * 2,
        marginTop: spacings.large,
        marginHorizontal: spacings.large * 2,
    },
    textInput: {
        color: "black",
        fontSize: 16,
        backgroundColor: "white",
        borderRadius: spacings.medium,
        padding: spacings.medium,
        textAlignVertical: "top",
        borderWidth: 1,
        borderColor: colors.primaryLight,
        height: "auto",
    },
    instructionMessage: {
        ...textStyles.heading,
        color: "white",
        textAlign: "center",
        marginBottom: spacings.large * 6,
    },
    button: {
        marginTop: spacings.large,
        borderWidth: 0,
        backgroundColor: colors.surfaceSecondaryLight,
    },


    topContainer: {
        flex: 1,
    },
    bottomContainer: {
        flex: 1,
        // borderWidth: 1,
    },
    checkIconContainer: {
        alignSelf: "center",
        marginBottom: spacings.large,
        marginTop: spacings.large * 8,
        height: 100,
        width: 100,
        borderRadius: 50,
        backgroundColor: "white",
        justifyContent: "center",
        alignItems: "center",

    },

    getLinkButton: {
        borderWidth: 0,
        backgroundColor: "white",
    },
    goBackMessageLink: {
        ...textStyles.heading3,
        color: colors.textOnSurfaceLight,
        marginTop: spacings.large,
        textAlign: "center",
        // borderWidth: 1,
    },



    subHeading: {
        ...textStyles.caption,
        color: colors.textLight,
        marginTop: spacings.small,
    },
    linkText: {
        ...textStyles.body,
        color: colors.text,
        marginVertical: spacings.large,
    },
    buttonsContainer: {
        flexDirection: "row",
    },



})