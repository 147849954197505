import React, { useContext, useEffect, useState, useCallback } from 'react';
import { ActivityIndicator, StatusBar, StyleSheet, ScrollView, Text, TextInput, View, SafeAreaView, Image, FlatList, useWindowDimensions, TouchableOpacity, KeyboardAvoidingView, TouchableWithoutFeedback, Keyboard, Modal, Share, Platform, Linking } from 'react-native';
import { colors, opacommunities, spacings, textStyles, textInputStyles, ButtonOne, ButtonOneUnfilled, ButtonTwo, SelectStory, DividerVertical, DividerHorizontal, ButtonTwoUnfilled } from '../context/DesignSystem';
import { UserContext } from '../context/UserContext';
import { MaterialIcons } from '@expo/vector-icons';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { getRandomColor, formatTime } from '../hooks/Hooks';
import { LinkMessageContext } from '../context/LinkMessageContext';

const UserLinkScreen = ({ user, navigation }) => {
    const insets = useSafeAreaInsets();
    const { linkMessages, createLinkMessage, createLinkMessageWithoutFirestoreUpdate } = useContext(LinkMessageContext);
    const [storyContainerBackgroundColor, setStoryContainerBackgroundColor] = useState(getRandomColor());
    const [message, setMessage] = useState("");
    const { rules } = useContext(UserContext);
    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const [textInputHeight, setTextInputHeight] = useState(0);


    // get screen height and width from useWindowDimensions
    const { width, height } = useWindowDimensions();
    const showWebView = width > 500 ? true : false;
    const showMootsLogo = width > (500 + 2 * (48 + spacings.large * 3)) ? true : false;
    const storyContainerWidth = width > 500 ? 500 : width;

    const appStoresUrl = rules?.urlBothAppStores ?? "https://mootsapp.app.link/moots";

    const handleSendMessage = async () => {
        try {
            const updatedLinkMessage = {
                compliment: message,
                pickedUser: user,
                pickedUserPhone: user.phone,
                backgroundColor: storyContainerBackgroundColor,
            };
            await createLinkMessage(updatedLinkMessage);
            // await createLinkMessageWithoutFirestoreUpdate(updatedLinkMessage);

        } catch (error) {
            console.log(error);
        }
    }


    return (
        <View style={[styles.container, {
            paddingTop: showWebView ? insets.top + spacings.large * 2 : insets.top,
            paddingBottom: showWebView ? insets.bottom + spacings.large * 2 : insets.bottom,
        }]}>
            {showMootsLogo && (
                <Image
                    style={{
                        width: 48,
                        height: 48,
                        position: "absolute",
                        top: spacings.large,
                        left: spacings.large * 2,
                        // left: 0,
                    }}
                    source={require('../../assets/icon_transparent.png')}
                />
            )}
            <View style={[styles.storyContainer, {
                backgroundColor: storyContainerBackgroundColor,
                width: storyContainerWidth,
            }]}>
                <View style={styles.topContainer}>
                    <View style={styles.userContainer}>
                        <View style={styles.userImage}>
                            <Text style={styles.userInitials}>
                                {user && user.pollLink && user.pollLink[0].toUpperCase()}
                            </Text>
                        </View>
                        <Text style={styles.userPollLink}>
                            {user && user.pollLink}
                        </Text>
                        <ButtonTwoUnfilled
                            title="Open in app"
                            onPress={() => {
                                // open appStoresUrl
                                window.location.href = appStoresUrl;
                            }}
                            style={styles.OpenInAppButton}
                            textStyle={{ color: "white", fontSize: 13 }}
                        />

                    </View>
                    <View
                        style={styles.sectionContainer}
                    >
                        <View style={styles.verifyEmailTextContainer}>
                            <Text style={styles.userMessage}>
                                Send me an anonymous message - compliment, question, confess - anything!
                            </Text>
                            <TextInput
                                style={styles.textInput}
                                value={message}
                                placeholder="Type your message here..."
                                placeholderTextColor={colors.textOnSurfaceSemiDark}
                                multiline={true}
                                maxLength={100}
                                textAlignVertical="top"
                                onChangeText={(text) => setMessage(text)}
                                onContentSizeChange={(event) => {
                                    setTextInputHeight(event.nativeEvent.contentSize.height)
                                }}
                            />
                        </View>
                    </View>
                    <Text style={styles.instructionMessage}>
                        <Text style={[styles.instructionMessage, { fontSize: 16 }]}>🎭 </Text>Your message will be sent anonymously
                    </Text>
                    {message.length > 0 && (
                        <ButtonOneUnfilled
                            title="Send"
                            disabled={message.length === 0 || isSendingMessage}
                            isLoading={isSendingMessage}
                            onPress={async () => {
                                setIsSendingMessage(true);
                                await handleSendMessage();
                                setIsSendingMessage(false);
                                navigation.navigate('SentMessageScreen', { pollLink: user.pollLink });
                                setMessage("");
                            }}
                            style={styles.button}
                        />
                    )}
                </View>
                <View style={styles.bottomContainer}>
                    <ButtonOneUnfilled
                        title="🔗 Get your own link"
                        onPress={() => {
                            // open appStoresUrl
                            window.location.href = appStoresUrl;
                        }}
                        style={styles.getLinkButton}
                        textStyle={{ color: "black" }}
                    />
                    <Text
                        style={styles.privacyLink}
                        onPress={() => {
                            Linking.openURL('https://www.moots.fun/privacy');
                        }}
                    >
                        Privacy Policy
                    </Text>
                </View>

            </View>
        </View>
    )
}

export default UserLinkScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: colors.backgroundDarkest,
        alignItems: "center",
        // borderWidth: 1,
    },
    storyContainer: {
        flex: 1,
        borderRadius: spacings.medium,
        padding: spacings.large * 2,
    },
    title: {
        ...textStyles.heading,
        fontSize: 28,
        color: "black",
        fontWeight: "bold",
        textAlign: "center",
        marginHorizontal: spacings.large,
    },
    subTitle: {
        ...textStyles.body,
        fontSize: 16,
        textAlign: "center",
        marginTop: spacings.small,
        marginHorizontal: spacings.large,
        marginBottom: spacings.large,
    },
    verifyEmailContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: spacings.large,
        paddingVertical: spacings.large * 2,
        borderRadius: spacings.large * 2,
        marginTop: spacings.large * 2,
        borderWidth: 1,
        borderColor: colors.borderDisabled,
    },
    sectionContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: colors.surfaceSecondaryLight,
        padding: spacings.large,
        paddingVertical: spacings.large * 2,
        borderRadius: spacings.large * 1.5,
        // marginTop: spacings.large * 2,
    },

    userContainer: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: spacings.large * 2,
    },
    userImage: {
        width: 40,
        height: 40,
        borderRadius: 20,
        backgroundColor: colors.textOnSurfaceLight,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: spacings.small,
        borderWidth: 2,
        borderColor: colors.secondaryDark,
    },
    userInitials: {
        fontSize: 20,
        fontWeight: "bold",
        color: colors.textOnSurfaceDarker,
        letterSpacing: 1,
    },
    userPollLink: {
        flex: 1,
        ...textStyles.heading,
        fontSize: 16,
        color: colors.textOnSurfaceLight,
        fontWeight: "600",
        letterSpacing: 0.5,
    },
    OpenInAppButton: {
        alignSelf: "center",
        marginLeft: "auto",
        marginRight: 0,
        height: 30,
        paddingHorizontal: spacings.large,
        borderColor: "white",
        backgroundColor: "transparent",
    },

    verifyEmailTextContainer: {
        flex: 1,
        marginHorizontal: spacings.large,
    },
    userMessage: {
        ...textStyles.heading2,
        color: "black",
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: spacings.large * 2,
        marginTop: spacings.large,
        marginHorizontal: spacings.large * 2,
    },
    textInput: {
        color: "black",
        fontSize: 16,
        backgroundColor: "white",
        borderRadius: spacings.medium,
        padding: spacings.medium,
        textAlignVertical: "top",
        borderWidth: 1,
        borderColor: colors.primaryLight,
        height: "auto",
    },
    instructionMessage: {
        ...textStyles.caption,
        color: "white",
        textAlign: "center",
        marginTop: spacings.large * 2,
    },
    button: {
        marginTop: spacings.large,
        borderWidth: 0,
        backgroundColor: colors.surfaceSecondaryLight,
    },


    topContainer: {
        flex: 1,
    },
    bottomContainer: {
        marginTop: spacings.large * 8,
    },
    getLinkButton: {
        borderWidth: 0,
        backgroundColor: "white",
    },
    privacyLink: {
        ...textStyles.heading4,
        color: colors.textOnSurfaceLight,
        marginTop: spacings.large,
        textAlign: "center",
    },



    subHeading: {
        ...textStyles.caption,
        color: colors.textLight,
        marginTop: spacings.small,
    },
    linkText: {
        ...textStyles.body,
        color: colors.text,
        marginVertical: spacings.large,
    },
    buttonsContainer: {
        flexDirection: "row",
    },



})