import React, { useContext, useState } from "react";
import { ActivityIndicator, StyleSheet, Text, View, TextInput, Button, TouchableOpacity, Image, ScrollView, Alert, Platform, useWindowDimensions } from 'react-native';
import { MaterialIcons } from '@expo/vector-icons';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "../firebase/Firebase";

const getWindowsHeight = () => {
    const { height: windowHeight } = useWindowDimensions();
    const PAGE_HEIGHT = windowHeight;
    return PAGE_HEIGHT;
}
const getWindowsWidth = () => {
    const { width: windowWidth } = useWindowDimensions();
    const PAGE_WIDTH = windowWidth;
    return PAGE_WIDTH;
}

// format firebases server's time in the format "now"/"2 min"/"1 hour"/"1 day"/"1 week"/"1 month"/"1 year"
const formatTime = (time) => {
    const timeInMilliseconds = time.seconds * 1000 + time.nanoseconds / 1000000
    const now = new Date();
    const createdAt = new Date(timeInMilliseconds);
    const diff = now - createdAt;
    const diffInMinutes = Math.floor(diff / 60000);
    const diffInHours = Math.floor(diff / 3600000);
    const diffInDays = Math.floor(diff / 86400000);
    const diffInWeeks = Math.floor(diff / 604800000);
    const diffInMonths = Math.floor(diff / 2592000000);
    const diffInYears = Math.floor(diff / 31536000000);
    if (diffInMinutes < 1) {
        return "now";
    } else if (diffInMinutes < 60) {
        return `${diffInMinutes}m`;
    } else if (diffInHours < 24) {
        return `${diffInHours}h`;
    } else if (diffInDays < 7) {
        return `${diffInDays}d`;
    } else if (diffInWeeks < 4) {
        return `${diffInWeeks}wk`;
    } else if (diffInMonths < 12) {
        return `${diffInMonths}mon`;
    } else {
        return `${diffInYears}yr`;
    }
};

const getRandomColor = () => {
    // get a color with a random combination of RGB values
    // r,g and b values should be between 50 and 150
    const r = Math.floor(Math.random() * 100) + 100;
    const g = Math.floor(Math.random() * 100) + 20;
    const b = Math.floor(Math.random() * 100) + 50;
    return `rgb(${r},${g},${b})`;
};

const uploadToStorageImagesFolder = async (localFileUrl, storageDocId) => {
    try {
        const imagesRef = ref(storage, `posts/${storageDocId}`);
        const img = await fetch(localFileUrl)
        const bytes = await img.blob()
        await uploadBytes(imagesRef, bytes);
        console.log("The file is uploaded");
        const url = await getDownloadURL(imagesRef);
        return url;
    }
    catch (error) {
        console.log("The error is: ", error);
        throw error;
        return {
            error: error.message
        };
    }
};
const uploadToStorageUserDpsFolder = async (localFileUrl, storageDocId) => {
    try {
        const imagesRef = ref(storage, `userDps/${storageDocId}`);
        const img = await fetch(localFileUrl)
        const bytes = await img.blob()
        await uploadBytes(imagesRef, bytes);
        console.log("The file is uploaded");
        const url = await getDownloadURL(imagesRef);
        return url;
    }
    catch (error) {
        console.log("The error is: ", error);
        throw error;
        return {
            error: error.message
        };
    }
};
const uploadToStorageVerificationsFolder = async (localFileUrl, storageDocId) => {
    try {
        const imagesRef = ref(storage, `verifications/${storageDocId}`);
        const img = await fetch(localFileUrl)
        const bytes = await img.blob()
        await uploadBytes(imagesRef, bytes);
        console.log("The file is uploaded");
        const url = await getDownloadURL(imagesRef);
        return url;
    }
    catch (error) {
        console.log("The error is: ", error);
        throw error;
        return {
            error: error.message
        };
    }
};
const uploadToStorageComplimentsFolder = async (localFileUrl, storageDocId) => {
    try {
        const imagesRef = ref(storage, `compliments/${storageDocId}`);
        const img = await fetch(localFileUrl)
        const bytes = await img.blob()
        await uploadBytes(imagesRef, bytes);
        console.log("The file is uploaded");
        const url = await getDownloadURL(imagesRef);
        return url;
    }
    catch (error) {
        console.log("The error is: ", error);
        throw error;
        return {
            error: error.message
        };
    }
};


export { getWindowsHeight, getWindowsWidth, formatTime, getRandomColor, uploadToStorageImagesFolder, uploadToStorageUserDpsFolder, uploadToStorageVerificationsFolder, uploadToStorageComplimentsFolder }