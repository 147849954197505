import * as React from 'react';
import { Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import MainNavigator from './MainNavigator';
import { UserProvider } from './src/context/UserContext';
import { LinkMessageProvider } from './src/context/LinkMessageContext';


const linking = {
  prefixes: ['https://www.moots.fun', 'moots://'],
  config: {
    screens: {
      Dynamic: ':pollLink',
      SentMessageScreen: ':pollLink/sent',
    },
  },
};

const App = () => {
  return (
    <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
      <MainNavigator />
    </NavigationContainer>
  );
};

export default () => {
  return (
    <UserProvider>
      <LinkMessageProvider>
        <App />
      </LinkMessageProvider>
    </UserProvider>
  );
};