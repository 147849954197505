import React, { useContext, useEffect, useState } from 'react';
import { ActivityIndicator, StatusBar, StyleSheet, ScrollView, Text, TextInput, View, SafeAreaView, Image, TouchableOpacity } from 'react-native';
import { colors, opacities, spacings, textStyles, textInputStyles, ButtonOne, ButtonOneUnfilled, ButtonTwo, SelectStory, DividerVertical, DividerHorizontal, ButtonTwoUnfilled } from '../context/DesignSystem';
import { MaterialIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';




const HeaderLeft = ({ pollLink }) => {
    const navigation = useNavigation();

    return (
        <View style={styles.container}>
            <TouchableOpacity
                onPress={() => {
                    navigation.navigate("Dynamic", { pollLink: pollLink })
                }}
                style={styles.backButtonContainer}

            >
                <MaterialIcons name="arrow-back-ios" size={26} color="white" />
            </TouchableOpacity>

        </View>
    )
}

export default HeaderLeft

const styles = StyleSheet.create({
    container: {
        padding: spacings.large * 2,
    },
    backButtonContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        // backgroundColor: 'red',
    },
    closeButtonContainer: {
        backgroundColor: colors.backgroundDark,
        padding: spacings.small,
        borderRadius: 100,
        justifyContent: 'center',
        alignItems: 'center',

    },
})