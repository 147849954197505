import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";


const firebaseConfig = {
    apiKey: "AIzaSyB-NkI3JAKqV4L-RObT3WiHY3atvOQr7es",
    authDomain: "moots-v1.firebaseapp.com",
    databaseURL: "https://moots-v1-default-rtdb.firebaseio.com",
    projectId: "moots-v1",
    storageBucket: "moots-v1.appspot.com",
    messagingSenderId: "573099541962",
    appId: "1:573099541962:web:e309b39620d09648a9bb33",
    measurementId: "G-DM32TL84DT"
};


const app = initializeApp(firebaseConfig);
// const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
// const analytics = getAnalytics(app);

export { app, db, storage };