import React, { useReducer, createContext, useEffect, useRef } from "react";
import { db, storage } from "../firebase/Firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { query, addDoc, getDocs, collection, where, getDoc, doc, setDoc, updateDoc, writeBatch, arrayUnion, arrayRemove, serverTimestamp, deleteDoc } from "firebase/firestore";

const UserContext = createContext();

const usersReducer = (state, action) => {
    switch (action.type) {
        case "CREATE_USER":
            return [...state, action.payload];
        case "UPDATE_USER":
            return state.map(user => {
                if (user.id === action.payload.id) {
                    return action.payload;
                } else {
                    return user;
                }
            });
        case "DELETE_USER":
            return state.filter(user => user.id !== action.payload.id);
        case "SET_USERS":
            return action.payload;
        default:
            return state;
    }
};
const rulesReducer = (state, action) => {
    switch (action.type) {
        case "GET_RULES":
            return action.payload;
        case "UPDATE_RULES":
            return { ...state, ...action.payload };
        default:
            return state;
    }
};


const UserProvider = ({ children }) => {
    const [users, usersDispatch] = useReducer(usersReducer, []);
    const [rules, rulesDispatch] = useReducer(rulesReducer, {});


    // never used it, but it might be useful in the future
    const emptyAllUserContext = () => {
        usersDispatch({ type: "SET_USERS", payload: [] });
        rulesDispatch({ type: "GET_RULES", payload: {} });
    };

    const fetchRules = async () => {
        const docRef = doc(db, "rules", "all");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            rulesDispatch({ type: "GET_RULES", payload: docSnap.data() });
        } else {
            console.log("No rules found");
        }
    };
    const updateRulesField = async (field, value) => {
        const docRef = doc(db, "rules", "all");
        await updateDoc(docRef, {
            [field]: value
        });
        rulesDispatch({ type: "UPDATE_RULES", payload: { [field]: value } });
    };

    const fetchUsers = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "users"));
            const users = querySnapshot.docs.map(doc => doc.data());
            usersDispatch({ type: "SET_USERS", payload: users });
            return users;
        } catch (error) {
            console.log(error);
        }
    };


    const isUserCreated = async (user) => {
        const q1 = query(collection(db, "users"), where("phone", "==", user.phone));
        const querySnap = await getDocs(q1);
        return querySnap.size > 0;
    }

    const createUser = async (user) => {
        let newUser = {};
        try {
            const q1 = query(collection(db, "users"), where("phone", "==", user.phone));
            const querySnap = await getDocs(q1);
            querySnap.forEach((doc) => {
                newUser = { ...doc.data() };
            });
            if (newUser.phone && newUser.isSignedUp) {
                return {
                    error: "User already exists"
                };
            }
            newUser = {
                ...user,
                createdAt: serverTimestamp(),
            };
            await setDoc(doc(db, "users", newUser.id), newUser);
            usersDispatch({
                type: "CREATE_USER",
                payload: newUser
            });
            return {
                user: newUser
            };
        } catch (error) {
            console.log(error);
            throw error;
            return {
                error: error.message
            };
        }
    };

    const updateCurrentUser = async (user) => {
        let updatedUser = {
            ...user,
            updatedAt: serverTimestamp()
        };
        try {
            await setDoc(doc(db, "users", updatedUser.id), updatedUser, { merge: true });
            usersDispatch({
                type: "UPDATE_USER",
                payload: updatedUser
            });
            setCurrentUserWithStorage(updatedUser);
            return {
                user: updatedUser
            };
        } catch (error) {
            console.log(error);
            return {
                error: error.message
            };
        }
    }

    const updateOtherUser = async (user) => {
        let updatedUser = {
            ...user,
            updatedAt: serverTimestamp()
        };
        try {
            await setDoc(doc(db, "users", updatedUser.id), updatedUser, { merge: true });
            usersDispatch({
                type: "UPDATE_USER",
                payload: updatedUser
            });
            return {
                user: updatedUser
            };
        } catch (error) {
            console.log(error);
            return {
                error: error.message
            };
        }
    }

    const deleteUser = async (user) => {
        try {
            await deleteDoc(doc(db, "users", user.id));
            usersDispatch({
                type: "DELETE_USER",
                payload: user
            });
            return {
                user
            };
        } catch (error) {
            console.log(error);
            return {
                error: error.message
            };
        }
    }


    // this function is not used anywhere, but it is a good example of how to upload a file to storage, and it was being used in the past :D
    const uploadFileToStorage = async (localFileUrl, storageDocId) => {
        try {
            const imagesRef = ref(storage, storageDocId);
            const img = await fetch(localFileUrl)
            const bytes = await img.blob()
            await uploadBytes(imagesRef, bytes);
            const url = await getDownloadURL(imagesRef);
            return url;
        }
        catch (error) {
            console.log("The Error part Run", error);
            return {
                error: error.message
            };
        }
    };




    return (
        <UserContext.Provider value={{ users, rules, fetchRules, updateRulesField, fetchUsers, isUserCreated, createUser, updateCurrentUser, updateOtherUser, deleteUser }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };